<template>
  <div class="pickUpGoodsDetails page-info-content">
    <Details-page
      :list-data="listData"
      :item-data="itemData"
      :table-title="'提货商品明细'"
      :detail-obj="detailObj"
      :details-item-arr="detailsItemArr"
    >
      <template>
        <el-descriptions title="提货单信息">
          <el-descriptions-item label="提货单号">
            {{ detailObj.deliveryNo }}
          </el-descriptions-item>
          <!-- <el-descriptions-item label="本次货款总价(元)">
            {{ detailObj.deliveryTotalPayment }}
          </el-descriptions-item>
          <el-descriptions-item label="本次代采费用(元)">
            {{ detailObj.agentCost }}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item label="本次提货总数量">
            {{ detailObj.deliveryTotalQuantity }}
          </el-descriptions-item> -->
          <el-descriptions-item label="本次提货总数量">
            {{ detailObj.deliveryTotalWeight }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Details-page>
    <!-- <div class="cancelButton">
      <el-button size="small" @click="$router.go(-1)">
        取消
      </el-button>
    </div> -->
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { deliveryInfoDetail } from '@/api/generationMining'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      itemData: [
        { width: '140px', label: '品名', prop: 'goodsName' },
        { width: '140px', label: '材质', prop: 'goodsMaterials' },
        { width: '140px', label: '规格', prop: 'goodsSpecs' },
        { width: '140px', label: '产地', prop: 'goodsProduct' },
        { width: '140px', label: '件重(吨)', prop: 'goodsWeights' },
        // { width: '140px', label: '采购数量', prop: 'applyAmount' },
        // { width: '140px', label: '采购数量(吨)', prop: 'purchaseWeight' },
        // { width: '140px', label: '入库数量', prop: 'applyAmount' },
        { width: '140px', label: '入库数量/单位', prop: 'invWeight', dynamicUnit: 'unit' },
        // { width: '140px', label: '提货数量', prop: 'applyAmount' },
        { width: '140px', label: '提货数量/单位', prop: 'applyWeight', dynamicUnit: 'unit' },
        // { width: '140px', label: '实际提货数量', prop: 'deliveryAmount' },
        { width: '140px', label: '实际提货数量/单位', prop: 'deliveryWeight', dynamicUnit: 'unit' }
        // { width: '140px', label: '采购单价(元/吨)', prop: 'purchasePrice' },
        // { width: '140px', label: '采购单位', prop: 'purchaseUnit', child: this.$store.getters.getDictionaryItem('purchaseUnit') }
        // { width: '140px', label: '价格合计(元)', prop: 'totalPrice' }
      ],
      listData: [],
      detailsItemArr: [
        { label: '合同编号', value: 'contractNo' },
        { label: '合作企业', value: 'supplierCmpName' },
        { label: '经销商', value: 'custCmpName' },
        { label: '代采商', value: 'fundsCmpName' },
        { label: '交收仓库', value: 'deliveryWarehouse' },
        // { label: '实提数量', value: 'deliveryTotalQuantity' },
        // { label: '实提数量(吨)', value: 'deliveryTotalWeight' },
        { label: '提货状态', value: 'deliveryStatus', child: this.$store.getters.getDictionaryItem('pick_up_goods_status') },
        { label: '申请日期', value: 'applyDate' },
        { label: '提货日期', value: 'deliveryDate' },
        { label: '提货车牌号', value: 'deliveryCarNo' },
        // { label: '提单校验码', value: 'deliveryCheckCode' },
        { label: '提货司机姓名', value: 'driverName' },
        { label: '司机电话', value: 'driverPhone' },
        { label: '提货申请单附件名称', value: 'deliveryFileName', link: 'deliveryFileUrl' },
        { label: '拒绝原因', value: 'refuseReason', whetherShown: true }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/pickUpGoods/index', name: '提货管理' }, { path: '', name: '提货详情' }]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      deliveryInfoDetail(this.$route.query.id, res => {
        this.detailObj = { ...res.data }
        if (res.data.goodsDetailList && res.data.goodsDetailList.length > 0) {
          const arr = this.$store.getters.getDictionaryItem('purchaseUnit') || []
          res.data.goodsDetailList.forEach(item => {
            item.unit = (arr.find(val => val.dictId === item.purchaseUnit) || {}).dictName
            item.goodsSpecs = item.goodsSpecs === '-1' ? '混合规格' : item.goodsSpecs
            item.goodsProduct = item.goodsProduct === '-1' ? '不限' : item.goodsProduct
            item.goodsWeights = item.goodsWeights === -1 ? '不限' : item.goodsWeights
          })
          this.listData = [...res.data.goodsDetailList]
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pickUpGoodsDetails {
  .cancelButton{
    text-align: center;
    margin-top: 16px;
  }
  .demo-info {
    padding: 32px;
    font-size: 14px;
    color: #606266;
  }

  /deep/ .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
    line-height: 2.5;
  }
  .operation {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
